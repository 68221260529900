/* DO NOT MODIFY, THIS FILE IS GENERATED BY THE TOGETAIR CLI.
THE TOGETAIR CLI IS COPYRIGHT MATERIAL OF TOGETAIR SPRL (togetair.com).
A COMMERCIAL LICENSE IS REQUIRED IN ORDER TO RUN THIS TOOLING.
CONTACT support@togetair.com IN CASE OF INQUIRY. */
var ___module_web = angular.module('web', ['togetair','ui.router']);;___module_web.name = "web";
View.defaultRoute('/intro');
var Body = function($__super) {
  Body.$inject = ["$rootScope", "$config", "GoogleAnalytics", "AnimateScrollTo"];
  function Body($rootScope, $config, GoogleAnalytics, AnimateScrollTo) {
    
    
    $traceurRuntime.superConstructor(Body).call(this);
    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
      if (fromState && fromState.name)
        document.body.classList.remove(fromState.name);
      if (toState && toState.name)
        document.body.classList.add(toState.name);
    });
    GoogleAnalytics.startTrackerWithId($config.googleAnalytics);
    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
      setTimeout(function() {
        return AnimateScrollTo(0, 800);
      }, 0);
      GoogleAnalytics.trackView(toState);
    });
  }
  return ($traceurRuntime.createClass)(Body, {link: function($scope, $element, $attrs) {}}, {}, $__super);
}(Directive);
Directive.register("Body", Body, {restrict: 'E'});
;
___module_web.directive('menu', ["$rootScope", "$sce", function($rootScope, $sce) {
  return {
    restrict: 'E',
    templateUrl: 'menu.html',
    replace: true,
    transclude: true,
    scope: {},
    controller: function() {
      var $__2 = this;
      this.items = ['<i class="ion-chevron-up"></i>', 'about', 'projects', 'contact'].map(function(i) {
        return $sce.trustAsHtml(i);
      });
      $rootScope.$watch('$scroll ', function(s) {
        return $__2.$scroll = s;
      });
    },
    controllerAs: 'view',
    bindToController: true
  };
}]);
;
var OnEnter = function($__super) {
  function OnEnter() {
    
    $traceurRuntime.superConstructor(OnEnter).call(this);
  }
  return ($traceurRuntime.createClass)(OnEnter, {link: function($scope, $element, $attrs) {
      $scope.$watch('view.onEnter', function(v) {
        if (v)
          $element[0].classList.add('entered');
      });
    }}, {}, $__super);
}(Directive);
Directive.register("OnEnter", OnEnter, {
  scope: {onEnter: '='},
  restrict: 'A'
});
;
var ScrollTo = function($__super) {
  function ScrollTo() {
    
    $traceurRuntime.superConstructor(ScrollTo).call(this);
  }
  return ($traceurRuntime.createClass)(ScrollTo, {link: function($scope, $element, $attrs) {
      var $__2 = this;
      $element.on('click', function() {
        $__2.invoke(["AnimateScrollTo", function(AnimateScrollTo) {
          
          
          var element = document.querySelector($attrs.scrollTo);
          var t = (document.body.scrollTop || window.scrollY) + element.getBoundingClientRect().top;
          AnimateScrollTo(t, 800);
        }]);
      });
    }}, {}, $__super);
}(Directive);
Directive.register("ScrollTo", ScrollTo, {restrict: 'A'});
;
var ScrollWatch = function($__super) {
  function ScrollWatch() {
    
    $traceurRuntime.superConstructor(ScrollWatch).call(this);
  }
  return ($traceurRuntime.createClass)(ScrollWatch, {link: function($scope, $element, $attrs) {
      this.invoke(["$rootScope", function($rootScope) {
        
        
        window.addEventListener('scroll', function(e) {
          var value = (document.body.scrollTop || window.scrollY) / (document.body.scrollHeight - document.body.clientHeight);
          $rootScope.$apply(function() {
            return $rootScope.$scroll = value;
          });
        });
      }]);
    }}, {}, $__super);
}(Directive);
Directive.register("ScrollWatch", ScrollWatch, {
  restrict: 'A',
  scope: true
});
;
var ScrollWheel = function($__super) {
  ScrollWheel.$inject = ["AnimateScrollTo"];
  function ScrollWheel(AnimateScrollTo) {
    
    
    $traceurRuntime.superConstructor(ScrollWheel).call(this);
    document.addEventListener('DOMContentLoaded', function() {
      return AnimateScrollTo(0, 500);
    });
  }
  return ($traceurRuntime.createClass)(ScrollWheel, {
    mouseWheel: function(e) {
      if (document.body.clientWidth > 480) {
        if (Math.abs(e.deltaY || e.detail) > 1) {
          var direction = (e.deltaY || e.detail) < 0 ? -1 : 1;
          var top = document.body.scrollTop || window.scrollY;
          var target = top - (top % document.body.clientHeight) + direction * document.body.clientHeight;
          this.invoke(["AnimateScrollTo", function(AnimateScrollTo) {
            
            
            AnimateScrollTo(target, 800);
          }]);
        }
        e.stopPropagation();
        e.preventDefault();
      }
    },
    link: function($scope, $element, $attrs) {
      var $__2 = this;
      window.addEventListener('DOMMouseScroll', function(e) {
        return $__2.mouseWheel(e);
      });
      window.addEventListener('mousewheel', function(e) {
        return $__2.mouseWheel(e);
      }, false);
    }
  }, {}, $__super);
}(Directive);
Directive.register("ScrollWheel", ScrollWheel, {restrict: 'A'});
;
___module_web.service('AnimateScrollTo', function() {
  var isActing,
      easeInOutCubic = function(t) {
        return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      };
  return function(top, duration) {
    if (!isActing) {
      var step = function(timestamp) {
        if (!start)
          start = timestamp;
        var progress = timestamp - start;
        document.body.scrollTop = init + delta * easeInOutCubic(progress / duration);
        document.documentElement.scrollTop = init + delta * easeInOutCubic(progress / duration);
        if (progress < duration)
          window.requestAnimationFrame(step);
      };
      isActing = true;
      var init = document.body.scrollTop || window.scrollY;
      var delta = top - init;
      var start;
      window.requestAnimationFrame(step);
    }
    setTimeout(function() {
      return isActing = false;
    }, duration);
  };
});
;
var Hiring = function($__super) {
  function Hiring() {
    $traceurRuntime.superConstructor(Hiring).apply(this, arguments);
  }
  return ($traceurRuntime.createClass)(Hiring, {}, {}, $__super);
}(View);
View.register("Hiring", Hiring);
;
var Intro = function($__super) {
  function Intro() {
    
    $traceurRuntime.superConstructor(Intro).call(this);
    this.users = [{
      name: 'Dim',
      description: "Passionate about bringing people together to create positive change",
      link: 'https://be.linkedin.com/in/dimitrideboose'
    }, {
      name: 'Jon',
      description: "Passionate about applying technology to create positive change",
      link: 'https://be.linkedin.com/in/jweber'
    }];
    this.goto = function(url) {
      window.open(url, '_blank');
    };
  }
  return ($traceurRuntime.createClass)(Intro, {}, {}, $__super);
}(View);
View.register("Intro", Intro, {module: ___module_web.name});
;
var Tools = function($__super) {
  function Tools() {
    
    $traceurRuntime.superConstructor(Tools).call(this);
    this.x = 7;
  }
  return ($traceurRuntime.createClass)(Tools, {afterEnter: function() {
      
      console.log('After ENTER triggered');
    }}, {}, $__super);
}(View);
View.register("Tools", Tools);
